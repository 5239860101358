import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback } from 'react';
import {
  GetLocationsResponse,
  GET_LOCATIONS,
  GetLocationParams
} from '../apollo/queries/getLocations';
import { convertFromUtc, formatDate, formatTimeFromIso } from '../helpers/dateHelper';
import useAuthState from './useAuthState';
import usePermission from './usePermission';

export interface UseTimezoneType {
  marketTimezones: () => Array<string>;
  handoverOrderTimezone: (locationId: string | undefined | null) => string;
  formatDateUtcIsoStringByLangTimezone: (
    dateString: string,
    timezone: string,
    userLanguage: string,
    format?: string
  ) => string;
  formatTimeUtcIsoStringByTimezone: (
    dateString: string,
    timezone: string,
    userLocale: string
  ) => string;
}

const useTimezone = () => {
  const { isMarketView, userMarketId } = useAuthState();
  const { filterAvailableLocations } = usePermission();
  const { data } = useQuery<GetLocationsResponse, GetLocationParams>(GET_LOCATIONS, {
    variables: {
      input: {
        isBackwardsQuery: false,
        paginationToken: null
      }
    },
    onError: e => console.error(e)
  });

  const marketTimezones = useCallback(() => {
    if (isMarketView) {
      const timezones =
        typeof data !== 'undefined'
          ? _.uniq(
              filterAvailableLocations(data?.locations?.locations)
                .filter(location => location.marketId === userMarketId)
                .map(loc => loc.timezone)
            )
          : [''];
      return timezones;
    } else return [''];
  }, [data, filterAvailableLocations, isMarketView, userMarketId]);

  const handoverOrderTimezone = useCallback(
    (locationId: string | undefined | null) => {
      return data?.locations?.locations.find(loc => loc.id === locationId)?.timezone ?? '';
    },
    [data?.locations]
  );

  const formatDateUtcIsoStringByLangTimezone = (
    dateString: string,
    timezone: string,
    userLanguage: string,
    format: string
  ) => {
    return formatDate(convertFromUtc(dateString, timezone), userLanguage, format);
  };

  const formatTimeUtcIsoStringByTimezone = (
    dateString: string,
    timezone: string,
    userLocale: string
  ) => {
    return formatTimeFromIso(convertFromUtc(dateString, timezone), userLocale);
  };

  return {
    marketTimezones,
    handoverOrderTimezone: handoverOrderTimezone,
    formatDateUtcIsoStringByLangTimezone,
    formatTimeUtcIsoStringByTimezone
  };
};
export default useTimezone;
