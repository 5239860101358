import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  Spacer,
  Spacings,
  Spinner,
  GridColumn,
  Grid,
  Icons,
  Alignments,
  Accordion,
  AccordionSection,
  Divider,
  Heading
} from '@polestar/component-warehouse-react';
import useDatoState from '~app/hooks/useDatoState';
import useAuthState from '~app/hooks/useAuthState';
import {
  GetLocationsResponse,
  GET_LOCATIONS,
  GetLocationParams
} from '~app/apollo/queries/getLocations';
import {
  UpdateUserSettingsResponse,
  UpdateUserSettingsRequest,
  UPDATE_USER_SETTINGS
} from '~app/apollo/mutations/updateUserSettings';
import { DealerLocation } from '~app/apollo/queries/getLocation';
import useMarketState from '~app/hooks/useMarketState';
import usePermission from '~app/hooks/usePermission';
import { generateUrl } from '~root/src/routes/helpers';
import { ArchivedTitle, GreyDivider, ResponsiveTitle, StyledTextButton } from './styles';
import { MARKET_USER } from '~root/src/app/apollo/queries/getRoles';
import { isNonEmptyString } from '~root/src/app/helpers/stringHelper';
import useTimezone from '~root/src/app/hooks/useTimezone';
import { Path } from '~root/src/routes/definition';

type Props = {
  onClose: Function;
  showArchivedLocations?: boolean;
  loaded: Dispatch<SetStateAction<boolean>>;
};

const ChangeLocationDialog = ({ onClose, showArchivedLocations = true, loaded }: Props) => {
  const { text } = useDatoState();
  const {
    user,
    userLocation,
    setUserLocation,
    userMarkets,
    userMarketId,
    isMarketView,
    setUserMarketId,
    setTimezone
  } = useAuthState();
  const { marketTimezones } = useTimezone();
  const { countries } = useMarketState();
  const { filterAvailableLocations } = usePermission();
  const [locations, setLocations] = useState<Array<DealerLocation>>([]);
  const [locationsByMarket, setLocationsByMarket] = useState<{
    [val: string]: Array<DealerLocation>;
  }>({});
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<GetLocationsResponse, GetLocationParams>(
    GET_LOCATIONS,
    {
      variables: {
        input: {
          isBackwardsQuery: false,
          paginationToken: null
        }
      },
      onError: e => console.error(e)
    }
  );
  const [updateUserSettings, { data: updatedUser, loading: updatingUser }] = useMutation<
    UpdateUserSettingsResponse,
    UpdateUserSettingsRequest
  >(UPDATE_USER_SETTINGS, { fetchPolicy: 'network-only' });

  const shouldGroupLocations = userMarkets.length > 1 || user.role.order > MARKET_USER;

  useEffect(() => {
    if (typeof data === 'undefined') {
      return;
    }
    const selectableLocations = filterAvailableLocations(data?.locations?.locations).sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    if (shouldGroupLocations) {
      const grouped = selectableLocations.reduce<{
        [val: string]: Array<DealerLocation>;
      }>((prev, location) => {
        const marketName = countries?.find(x => x.id === location.marketId)?.name;
        if (marketName === undefined) {
          return prev;
        }
        return Object.assign(prev, { [marketName]: (prev[marketName] || []).concat(location) });
      }, {});
      setLocationsByMarket(grouped);
    }
    setLocations(selectableLocations);
  }, [countries, data, filterAvailableLocations, shouldGroupLocations]);

  useEffect(() => {
    if (updatedUser == null) {
      return;
    }

    const newCurrentLocation = locations.find(
      x => x.id === updatedUser.updatedUser.settings?.currentLocationId
    );
    if (newCurrentLocation === undefined) {
      console.error('location is undefined');
      return;
    }

    setUserLocation(newCurrentLocation);
    setTimezone(newCurrentLocation.timezone);

    const newCurrentMarket = updatedUser.updatedUser.settings?.currentMarketId;
    if (isNonEmptyString(newCurrentMarket)) {
      setUserMarketId(newCurrentMarket);
      navigate(generateUrl(Path.ORDERS));
    } else {
      setUserMarketId(undefined);
      navigate(generateUrl(Path.HOME));
    }

    onClose();
  }, [
    countries,
    history,
    locations,
    marketTimezones,
    onClose,
    setTimezone,
    setUserLocation,
    setUserMarketId,
    updatedUser,
    userLocation
  ]);

  const printMarketViewOpt = useCallback(
    (marketLocation: DealerLocation, marketName: string) => {
      return (
        <>
          <StyledTextButton
            disabled={updatingUser}
            icon={marketLocation.marketId === userMarketId ? Icons.checkCircle : undefined}
            iconPosition={Alignments.right}
            selected={marketLocation.marketId === userMarketId}
            onClick={() => {
              if (marketLocation.marketId !== userMarketId) {
                updateUserSettings({
                  variables: {
                    input: {
                      userProfileId: user.id,
                      currentLocationId: marketLocation.id,
                      currentMarketId: marketLocation.marketId
                    }
                  }
                });
              }
            }}
            data-testid={`all-market-${marketName}-btn`}
          >
            {text('MarketView') + ' ' + marketName}
          </StyledTextButton>

          <GreyDivider />
        </>
      );
    },
    [text, updateUserSettings, updatingUser, user.id, userMarketId]
  );

  const printLocations = useCallback(
    (locs: Array<DealerLocation>) => {
      if (locs.length === 0) {
        return (
          <div>
            <Heading level={2} data-testid="no-locations-info">
              No Locations available, please contact support
            </Heading>
            <a href="mailto:handover.support@polestar.com">handover.support@polestar.com</a>
          </div>
        );
      }

      const archivedLocations = locs.filter(location => location.archived);

      const renderLocation = (x: DealerLocation) => {
        return (
          <GridColumn
            span={{ desktop: 6, tablet: 4, mobile: 4 }}
            key={x.id}
            data-testid={`location-${x.id}`}
          >
            <StyledTextButton
              disabled={updatingUser}
              icon={!isMarketView && x.id === userLocation.id ? Icons.checkCircle : undefined}
              iconPosition={Alignments.right}
              selected={!isMarketView && x.id === userLocation.id}
              onClick={() => {
                if (isMarketView || x.id !== userLocation.id) {
                  updateUserSettings({
                    variables: {
                      input: {
                        userProfileId: user.id,
                        currentLocationId: x.id,
                        currentMarketId: ''
                      }
                    }
                  });
                }
              }}
              data-testid={`location-${x.id}-btn`}
            >
              {x.name}
            </StyledTextButton>
          </GridColumn>
        );
      };

      const renderLoc = locs.map(x => !x.archived && renderLocation(x));
      const renderArch = archivedLocations.map(x => renderLocation(x));

      const shouldShowArchived = (): boolean => showArchivedLocations && renderArch.length > 0;

      return (
        <>
          <Grid>{renderLoc}</Grid>
          {shouldShowArchived() && (
            <>
              <GreyDivider />
              <Spacer spacing={Spacings.xxSmall} />
              <ArchivedTitle>{text('ArchivedLocations')}</ArchivedTitle>
              <Grid style={{ paddingTop: '1rem' }}>{renderArch}</Grid>
            </>
          )}
        </>
      );
    },
    [
      isMarketView,
      showArchivedLocations,
      text,
      updateUserSettings,
      updatingUser,
      user.id,
      userLocation.id
    ]
  );

  if (error) {
    throw error;
  }

  if (data) {
    loaded(true);
  }
  const hasSelectedLocation = (market: string, marketLocations: DealerLocation[]): boolean =>
    (isMarketView && market === userMarketId) ||
    (!isMarketView && marketLocations.find(a => a.id === userLocation.id))
      ? true
      : false;

  const selectedMarket = (): string => (isMarketView ? userMarketId ?? '' : userLocation.marketId);

  return (
    <>
      <Heading level={1} data-testid="change-location">
        {text('SelectLocation')}
      </Heading>
      <Spacer spacing={Spacings.medium} />

      {loading || data === undefined ? (
        <Spinner />
      ) : (
        <>
          {!shouldGroupLocations ? (
            <>
              {locations.length > 1 &&
                user.role.order >= MARKET_USER &&
                printMarketViewOpt(
                  locations[0],
                  countries?.find(x => x.id === locations[0].marketId)?.name ?? ''
                )}
              {printLocations(locations)}
            </>
          ) : Object.keys(locationsByMarket).length <= 2 ? (
            Object.entries(locationsByMarket)
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(([market, marketLocations], index) => (
                <React.Fragment key={market}>
                  <Heading level={2} data-testid={`group-${market}`}>
                    {market}
                  </Heading>

                  <Spacer spacing={Spacings.large} />

                  {marketLocations.length > 1 &&
                    user.role.order >= MARKET_USER &&
                    printMarketViewOpt(marketLocations[0], market)}
                  {printLocations(marketLocations)}
                  {index < Object.entries(locationsByMarket).length - 1 && (
                    <Divider spacing={Spacings.xxxLarge} />
                  )}
                </React.Fragment>
              ))
          ) : (
            <Accordion initialExpanded={{ [selectedMarket()]: true }}>
              {Object.entries(locationsByMarket)
                .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                .map(([market, marketLocations], index) => (
                  <AccordionSection
                    id={marketLocations[0].marketId}
                    key={index}
                    title={
                      <ResponsiveTitle
                        hasSelectedLocation={hasSelectedLocation(market, marketLocations)}
                        data-testid={`group-${market}`}
                      >
                        {market}
                      </ResponsiveTitle>
                    }
                    data-testid={`market-${marketLocations[0].marketId}`}
                  >
                    {user.role.order >= MARKET_USER &&
                      printMarketViewOpt(marketLocations[0], market)}

                    {printLocations(marketLocations)}
                  </AccordionSection>
                ))}
            </Accordion>
          )}
        </>
      )}
    </>
  );
};

export default ChangeLocationDialog;
