import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { DocumentType } from '~app/hooks/useDocuments';
import {
  getOrderContractMock,
  mockOrderResponse,
  OrderContract,
  OrderlineStatus
} from './getOrder';
import { BookingStatuses, deRegistrationInformationStatus, NoteType } from '~app/enums';
import { TimeslotInput } from './getBatch';
import { RegistrationState } from '~enums/Filters';

export const handoverQuery = (isTableData: boolean) => `
orderNumber
vin
locationId
marketId
estimatedArrival
handoverState
vehiclePreparationState
vehiclePreparationRequirements{
  preparations
  orderedExtras
}
handoverPreparationState
marketAdaptionState
assignedToUserProfileId
assignedToUserName
booking {
  handoverDate
  handoverEndDate
  handoverSecondaryDate
  handoverSecondaryEndDate
  bookingStatus
}
preparations {
  preparationId
  date
  userId
  userName
}
customer {
  firstName
  lastName
}
orderData {
  orderType
  orderState
  vehicleRegistrationNumber
  purchaseOrderNumber
  actualBrandStatusPointNumber
  actualBrandStatusDate
  lockState
  financeMethod
  useRegistrationAgency
  handoverMethod
  registrationDate
  commercialQualityHoldStatus
  commercialDeliveryHoldStatus
  marketAdaptionDate
  finalPaymentBuyerStatus
  deSpeedRegistrationStatus
  needsWinterWheelMounting
  handoverLocation {
    code
    name
    address
    zipCode
    city
    countryCode
  }
  pdiPdsLocation
  {
    code
    name
  }
  handoverPartners {
    locationId
    capabilities
  }
  handoverCompletedAt
  deliveryMethod
  invoiceMethod
  model
  modelYear
  modelCode
  performance
  chassisNumber
  roleHolders {
    type
    roles
    name
    firstName
    lastName
    organizationNumber
    email
    phone
  }
  edition
  additionalUpgrades {
    code
    replacementPartNumber
  }
}
extraPackages {
  orderNumber
  partNumber
  orderlineId
  orderlineStatus
  winterTireWantsTogetherWithCar
  productType
  packageId
  salesItemId
  quantity
  isMandatory
  fallbackName
  products {
    partNumber
    quantity
  }
}
manuallySetDeliveryDate
deliveryAcceptanceComment
insurancePolicyNumber
wheelTransport {
  requestState
  requestedAt
  transporter
  trackingUrl
  printOutUrl
  bookingReference
}
feedback {
  hasThumbUp
  comment
  userId
}
 
supportTicket {
  contactEmail
  comment
  toEmail
}
softwareData {
  version
  versionTimestamp
}
batch {
  id
  scheduledPickupTime {
    start
    end
  }
  actualPickupTime
  scheduledHandoverTime {
    start
    end
  }
  actualHandoverTime
}
notesCount

deliveryStatus {
  communicatedDeliveryStartDateAtPointOfOrder
  communicatedDeliveryEndDateAtPointOfOrder
  plannedDeliveryDateToHandoverSite
  earliestAllowedHandoverDate
}

vehicleRegistrationState
${
  isTableData
    ? ''
    : `
    identificationNumber
    identificationType
    odometer
    stateOfCharge
    notes {
      id
      text
      type
      created
      addedBy
      title
    }
    documents {
      id
      documentType
      subType
      documentId
      signingStatus
      completionDate
      startDate
      errorType
      errorMessage
      errorDate
    }
  `
}
`;

const customerSummaryQuery = `
  aiSummary
  detailedSummary {
    header
    value
  }
  recommendation
  aiUpdatedDate
`;

export const GET_HANDOVER_EXTENDED = gql`
  query getHandoverExtended($input: GetHandoverRequest!) {
    response: getHandoverExtended(input: $input) {
      handover {
        ${handoverQuery(false)}
      }
      orders {
        orderNumber
        orderEventJson
      }
      customerSummary {
        ${customerSummaryQuery}
      }
    }
  }
`;

export enum MarketAdaptionStates {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum WheelTransportRequestState {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export type HandoverState =
  | 'IN_TRANSIT'
  | 'PREPARATION'
  | 'DONE'
  | 'CANCELLED'
  | 'PARTIALLY_DELIVERED';

export type VehiclePreparationState =
  | 'NOT_APPLICABLE'
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETED';

export type HandoverPreparationState = 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED';

export type PreparationStep = 'pdi' | 'pds' | string;

export const getExtraPreparationStepId = (extraId: string, orderNumber: string) =>
  `extra_${extraId}_${orderNumber}`;
export const getExtraEquipmentPreparationStepId = (id: string) => `xequip_${id}`;

export interface Preparation {
  preparationId: PreparationStep;
  date: string;
  userId: string;
  userName: string;
}

export interface VehiclePreparationRequirements {
  preparations: Array<PreparationStep>;
  orderedExtras: Array<string>;
}

export type IdentificationType = 'driving_license' | 'passport' | 'identification_card';

export type SigningStatus =
  | 'SIGNING_INITIATED'
  | 'WAITING_FOR_UPLOAD'
  | 'PRESIGNED_URL_SENT'
  | 'COMPLETED'
  | 'ERROR';

export type SigningErrorType = 'CANCELED' | 'PERMANENT' | 'TRANSIENT';

type HandoverLocation = {
  code?: string | null;
  name?: string | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  countryCode?: string | null;
};
type PdiPdsLocation = {
  code?: string | null;
  name?: string | null;
};

type HandoverPartner = {
  locationId: string;
  capabilities: Array<string>;
};

type WheelTransport = {
  requestState?: WheelTransportRequestState | null;
  requestedAt?: string | null;
  transporter?: string | null;
  trackingUrl?: string | null;
  printOutUrl?: string | null;
  bookingReference?: string | null;
};

type Feedback = {
  hasThumbUp?: boolean | null;
  comment?: string | null;
  userId?: string | null;
};

type SpecialistSupportTicket = {
  contactEmail?: string | null;
  comment?: string | null;
  toEmail?: Array<string> | null;
};

type SoftwareData = {
  version?: string | null;
  versionTimestamp?: string | null;
};

export enum RoleHolderType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
  FSP = 'FSP',
  FMC = 'FMC'
}

export type DeliveryStatus = {
  communicatedDeliveryStartDateAtPointOfOrder?: string;
  communicatedDeliveryEndDateAtPointOfOrder?: string;
  plannedDeliveryDateToHandoverSite?: string;
  earliestAllowedHandoverDate?: string;
};

export type RoleHolder = {
  type: RoleHolderType;
  roles: Array<string>;
  name?: string;
  firstName?: string;
  lastName?: string;
  organizationNumber?: string;
  email?: string;
  phone?: string;
};

type ExtraProduct = {
  partNumber?: string | null;
  quantity?: number | null;
};

export type ExtraPackage = {
  orderNumber?: string | null;
  partNumber?: string | null;
  orderlineId?: number | null;
  orderlineStatus?: OrderlineStatus;
  winterTireWantsTogetherWithCar?: boolean | null;
  productType?: string | null;
  packageId?: string | null;
  salesItemId?: number | null;
  quantity?: number | null;
  isMandatory?: boolean | null;
  fallbackName?: string | null;
  products?: Array<ExtraProduct> | null;
};

type BatchData = {
  id: string;
  scheduledPickupTime?: TimeslotInput | null;
  actualPickupTime?: string | null;
  scheduledHandoverTime?: TimeslotInput | null;
  actualHandoverTime?: string | null;
};

type AdditionalUpgrade = {
  code?: string | null;
  replacementPartNumber?: string | null;
};

export type CustomerSummary = {
  aiSummary?: string | null;
  detailedSummary?: Array<{ header: string; value: string }> | null;
  recommendation?: Array<string> | null;
  aiUpdatedDate?: string | null;
};

export interface HandoverTableData {
  orderNumber: string;
  locationId?: string | null;
  marketId?: string | null;
  handoverState: HandoverState;
  vehiclePreparationState: VehiclePreparationState;
  handoverPreparationState: HandoverPreparationState;
  marketAdaptionState?: MarketAdaptionStates | null;
  assignedToUserProfileId?: string | null;
  assignedToUserName?: string | null;
  vin?: string | null;
  customer?: {
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  booking?: {
    handoverDate?: string | null;
    handoverEndDate?: string | null;
    handoverSecondaryDate?: string | null;
    handoverSecondaryEndDate?: string | null;
    bookingStatus: BookingStatuses;
  } | null;
  estimatedArrival?: string | null;
  preparations?: Array<Preparation> | null;
  vehiclePreparationRequirements?: VehiclePreparationRequirements;
  notesCount?: number | null;
  orderData?: OrderData | null;
  documents?: Array<Document> | null;
  extraPackages?: Array<ExtraPackage> | null;
  manuallySetDeliveryDate?: string | null;
  deliveryAcceptanceComment?: string | null;
  insurancePolicyNumber?: string | null;
  wheelTransport?: WheelTransport | null;
  feedback?: Feedback | null;
  supportTicket?: SpecialistSupportTicket | null;
  softwareData?: SoftwareData | null;
  batch?: BatchData | null;
  notes?: Array<Note> | null;
  deliveryStatus?: DeliveryStatus;
  vehicleRegistrationState?: string | null;
}

export interface Handover extends HandoverTableData {
  stateOfCharge?: number | null;
  odometer?: string | null;
  identificationNumber?: string | null;
  identificationType?: IdentificationType | null;
  documents?: Array<Document> | null;
  insurancePolicyNumber?: string | null;
}

interface OrderData {
  orderType?: string | null;
  orderState?: string | null;
  vehicleRegistrationNumber?: string | null;
  purchaseOrderNumber?: string | null;
  actualBrandStatusPointNumber?: number | null;
  actualBrandStatusDate?: string | null;
  useRegistrationAgency?: boolean | null;
  lockState?: string | null;
  financeMethod?: string | null;
  handoverMethod?: string | null;
  registrationDate?: string | null;
  commercialQualityHoldStatus?: boolean | null;
  commercialDeliveryHoldStatus?: boolean | null;
  marketAdaptionDate?: string | null;
  finalPaymentBuyerStatus?: string | null;
  deSpeedRegistrationStatus?: keyof typeof deRegistrationInformationStatus | null;
  needsWinterWheelMounting?: boolean | null;
  handoverLocation?: HandoverLocation | null;
  pdiPdsLocation?: PdiPdsLocation | null;
  handoverPartners?: Array<HandoverPartner> | null;
  handoverCompletedAt?: string | null;
  deliveryMethod?: string | null;
  invoiceMethod?: string | null;
  model?: string | null;
  modelYear?: string | null;
  modelCode?: string | null;
  performance?: string | null;
  chassisNumber?: string | null;
  roleHolders?: Array<RoleHolder> | null;
  edition?: string | null;
  additionalUpgrades?: Array<AdditionalUpgrade> | null;
}

export interface Note {
  id: string;
  type?: NoteType;
  text?: string;
  created: string;
  addedBy: string;
  title?: string;
}

export type OrderResponseType = {
  orderNumber: string;
  orderEventJson: string;
};

export interface GetHandoverExtendedResponse {
  response: {
    handover: Handover;
    orders: [OrderResponseType];
    customerSummary: CustomerSummary;
  };
}

export interface GetHandoverExtendedParams {
  input: { orderNumber: string };
}

export interface Document {
  documentType: DocumentType;
  id: string;
  subType?: string;
  documentId?: string | null;
  signingStatus?: SigningStatus;
  completionDate?: string;
  startDate?: string;
  errorType?: SigningErrorType;
  errorMessage?: string;
  errorDate?: string;
}

export const getMainOrder = (orders: Array<OrderResponseType>, mainOrderNumber: string) => {
  return JSON.parse(
    orders.filter(order => order.orderNumber === mainOrderNumber)[0].orderEventJson
  ) as OrderContract;
};

export const getRelatedOrders = (orders: Array<OrderResponseType>, mainOrderNumber: string) => {
  const relatedExtraOrders = getMainOrder(orders, mainOrderNumber)?.data?.related_extra_orders;
  return orders
    .filter(
      order =>
        order.orderNumber !== mainOrderNumber && relatedExtraOrders?.includes(order.orderNumber)
    )
    .map(relatedOrder => JSON.parse(relatedOrder.orderEventJson as string) as OrderContract);
};

export const getHandoverMock = (orderNumber: string, data: Partial<Handover> = {}): Handover => ({
  orderNumber: orderNumber,
  insurancePolicyNumber: '',
  vin: '111',
  locationId: '321',
  marketId: 'SE',
  estimatedArrival: '',
  handoverState: 'IN_TRANSIT',
  vehiclePreparationState: 'NOT_STARTED',
  handoverPreparationState: 'NOT_STARTED',
  marketAdaptionState: null,
  assignedToUserProfileId: '',
  assignedToUserName: '',
  stateOfCharge: 0,
  deliveryAcceptanceComment: '',
  notes: [],
  notesCount: null,
  documents: [],
  extraPackages: [],
  manuallySetDeliveryDate: '',
  preparations: [],
  vehiclePreparationRequirements: {
    preparations: [],
    orderedExtras: [],
    ...data.vehiclePreparationRequirements
  },
  odometer: '',
  identificationNumber: '',
  identificationType: 'driving_license',
  deliveryStatus: {
    communicatedDeliveryEndDateAtPointOfOrder: '',
    communicatedDeliveryStartDateAtPointOfOrder: '',
    plannedDeliveryDateToHandoverSite: '',
    earliestAllowedHandoverDate: ''
  },
  vehicleRegistrationState: RegistrationState.IN_PROGRESS,
  ...data,
  wheelTransport: {
    bookingReference: '',
    printOutUrl: '',
    requestedAt: '',
    transporter: '',
    requestState: null,
    trackingUrl: '',
    ...data.wheelTransport
  },
  feedback: {
    hasThumbUp: true,
    comment: '',
    userId: '',
    ...data.feedback
  },
  supportTicket: {
    contactEmail: 'contact@email.se',
    comment: '',
    toEmail: ['to@email.se'],
    ...data.supportTicket
  },
  softwareData: {
    version: '1.3.3.7',
    versionTimestamp: '2020-01-10T12:00:00Z',
    ...data.softwareData
  },
  booking: {
    handoverDate: '',
    handoverEndDate: '',
    handoverSecondaryEndDate: '',
    handoverSecondaryDate: '',
    bookingStatus: BookingStatuses.SCHEDULE,
    ...data.booking
  },
  customer: {
    firstName: '',
    lastName: 'Andersson',
    ...data.customer
  },
  orderData: {
    orderType: '',
    orderState: '',
    vehicleRegistrationNumber: '',
    actualBrandStatusPointNumber: 0,
    actualBrandStatusDate: '',
    useRegistrationAgency: null,
    purchaseOrderNumber: '',
    marketAdaptionDate: '',
    finalPaymentBuyerStatus: '',
    deSpeedRegistrationStatus: 'appointment_selected',
    needsWinterWheelMounting: false,
    financeMethod: '',
    handoverMethod: '',
    lockState: '',
    registrationDate: '',
    handoverPartners: [],
    handoverCompletedAt: '',
    deliveryMethod: '',
    invoiceMethod: '',
    model: '',
    modelYear: '',
    modelCode: '',
    performance: '',
    chassisNumber: '',
    commercialQualityHoldStatus: false,
    commercialDeliveryHoldStatus: false,
    roleHolders: [],
    edition: '',
    additionalUpgrades: [],
    ...data.orderData,
    handoverLocation: {
      address: '',
      city: '',
      code: '',
      countryCode: '',
      name: '',
      zipCode: '',
      ...data.orderData?.handoverLocation
    },
    pdiPdsLocation: { code: '', name: '', ...data.orderData?.pdiPdsLocation }
  },
  batch: {
    id: '',
    scheduledPickupTime: { start: '', end: '', ...data.batch?.scheduledPickupTime },
    actualPickupTime: '',
    scheduledHandoverTime: { start: '', end: '', ...data.batch?.scheduledHandoverTime },
    actualHandoverTime: '',
    ...data.batch
  }
});

export const getHandoverExtendedApolloMock = (
  orderNumber: string,
  handoverResult: Partial<Handover> = {},
  orderResult: Partial<OrderContract> = mockOrderResponse,
  cusomterSummaryResult: Partial<CustomerSummary> = {}
): {
  request: { query: DocumentNode; variables: GetHandoverExtendedParams };
  result: { data: GetHandoverExtendedResponse };
} => ({
  request: {
    query: GET_HANDOVER_EXTENDED,
    variables: {
      input: {
        orderNumber: orderNumber
      }
    }
  },
  result: {
    data: {
      response: {
        handover: getHandoverMock(orderNumber, handoverResult),
        orders: [
          {
            orderNumber: orderNumber,
            orderEventJson: JSON.stringify(getOrderContractMock(orderResult))
          }
        ],
        customerSummary: cusomterSummaryResult
      }
    }
  }
});
