import React, { ReactNode } from 'react';
import {
  Colors,
  Icon,
  Icons,
  Opacities,
  Sizes,
  withOpacity
} from '@polestar/component-warehouse-react';
import * as Styled from '~app/views/OrdersView/styles';
import NotApplicableIcon from '~app/components/Shared/NotApplicableIcon';
import CalendarCrossIcon from '~app/components/Shared/CalendarCrossIcon';
import PendingIcon from '~app/components/Shared/PendingIcon';
import { InternalColors } from '@polestar/component-warehouse-react/internal';

export const checkIcon = (
  <Styled.CellIcon icon={Icons.check} size={Sizes.small} pairedWithText={true} />
);
export const minusIcon = (
  <Styled.CellIcon icon={Icons.minus} size={Sizes.small} pairedWithText={true} />
);
export const lockedIcon = (
  <Styled.CellIcon icon={Icons.lock} size={Sizes.small} pairedWithText={true} />
);
export const refreshIcon = (
  <Styled.CellIcon icon={Icons.refresh} size={Sizes.small} pairedWithText={true} />
);
export const unlockedIcon = (
  <Styled.CellIcon icon={Icons.lockOpen} size={Sizes.small} pairedWithText={true} />
);
export const markerIcon = (
  <Icon
    style={{ verticalAlign: 'middle' }}
    icon={Icons.marker}
    size={Sizes.small}
    pairedWithText={true}
  />
);
export const arrowRightIcon = (
  <Styled.CellIcon icon={Icons.arrowRight} size={Sizes.small} pairedWithText={true} />
);
export const warningIcon = (
  <Styled.CellIcon icon={Icons.warning} size={Sizes.small} pairedWithText={true} />
);
export const notApplicableIcon = <NotApplicableIcon style={{ verticalAlign: 'middle' }} />;
export const calendarCrossIcon = <CalendarCrossIcon style={{ verticalAlign: 'middle' }} />;
export const pendingIcon = <PendingIcon style={{ verticalAlign: 'middle' }} />;

/**
 * @param icon should be type Icon unless non-CW icons are used
 */
export type OrderTableCell = {
  value: string | ReactNode | null;
  valueString: string;
  icon?: ReactNode;
  hideIconWhenExpanded?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  fontColor?: string;
};

export type OrderCell = {
  name: string;
  icon?: ReactNode;
  color?: string;
};

export type OrderColumn = {
  name: string;
  icon?: ReactNode;
  color?: string;
  subColumns?: Array<OrderCell>;
};

export const NOT_APPLICABLE_CELL: OrderTableCell = {
  value: null,
  valueString: 'N/A',
  icon: notApplicableIcon,
  borderColor: 'inherit',
  backgroundColor: withOpacity(Colors.agatheGrey, Opacities.xLow)
};

export const DISABLED_CELL: OrderTableCell = {
  value: 'Disabled',
  valueString: 'Disabled',
  icon: minusIcon,
  hideIconWhenExpanded: true,
  borderColor: 'inherit',
  backgroundColor: withOpacity(Colors.agatheGrey, Opacities.xLow)
};

export const DEFAULT_CELL = { value: 'Not implemented yet', valueString: 'Not implemented yet' };

export const COMPLETED_CELL = {
  value: 'Completed',
  valueString: 'Completed',
  icon: checkIcon,
  borderColor: InternalColors.systemGreen
};

export const IN_PROGRESS_CELL = {
  value: 'In Progress',
  valueString: 'In Progress',
  icon: pendingIcon,
  borderColor: InternalColors.systemYellow
};

export const NOT_STARTED_CELL = {
  value: 'Not started',
  valueString: 'Not started',
  icon: minusIcon,
  borderColor: Colors.agatheGrey
};

export const NOT_ORDERED_CELL = {
  value: 'Not ordered',
  valueString: 'Not ordered',
  borderColor: Colors.agatheGrey,
  icon: minusIcon
};
export const ORDERED_CELL = {
  value: 'Ordered',
  valueString: 'Ordered',
  borderColor: InternalColors.systemYellow,
  icon: checkIcon
};

export const INSTALLED_AT_FACTORY_YELLOW_CELL = {
  value: 'Installed at the factory',
  valueString: 'Installed at the factory',
  borderColor: InternalColors.systemYellow,
  icon: checkIcon
};

export const INSTALLED_AT_FACTORY_GREEN_CELL = {
  value: 'Installed at the factory',
  valueString: 'Installed at the factory',
  borderColor: InternalColors.systemGreen,
  icon: checkIcon
};

export const INSTALLATION_CONFIRMED_CELL = {
  value: 'Installation confirmed',
  valueString: 'Installation confirmed',
  borderColor: InternalColors.systemGreen,
  icon: checkIcon
};
