import React, { useState, useCallback } from 'react';
import DatoContext from './context';
import { parse } from 'query-string';
import { useApolloClient } from '@apollo/client';
import {
  GetDeliveryAcceptanceConsentResponse,
  GET_DELIVERY_ACCEPTANCE_CONSENT
} from '~app/apollo/queries/getHandoverDeliveryAcceptanceConsent';
import { GetFspVatResponse, GET_FSP_VAT } from '~app/apollo/queries/getFspVat';
import {
  GetExtraDocAndEquipmentResponse,
  GET_EXTRA_DOCS_AND_EQUIPMENT
} from '~app/apollo/queries/getExtraDocAndEquipment';
import { isNonEmptyString, isNullishOrEmpty } from '~app/helpers/stringHelper';

const DatoContextProvider = (props: any) => {
  const [translation, setTranslation] = useState<{ [value: string]: string }>({});
  const client = useApolloClient();

  const text = useCallback(
    (prop: string, testText?: string) => {
      if (process.env.JEST_WORKER_ID !== undefined && testText !== undefined) {
        return testText;
      }
      const getParams = parse(location.search);
      if (getParams['show_dato_labels'] !== undefined) {
        return prop;
      }
      return Object.prototype.hasOwnProperty.call(translation, prop)
        ? translation[prop].length > 0
          ? translation[prop]
          : `${prop}##`
        : `${prop}**`;
    },
    [translation]
  );

  const addTranslation = useCallback((list: { [value: string]: string }) => {
    setTranslation(prev => ({ ...prev, ...list }));
  }, []);

  const getConsent = async (
    isVolvoFinans: boolean,
    userLanguage: string,
    fspOrgNumber: string,
    comment: string
  ) => {
    return client
      .query<GetDeliveryAcceptanceConsentResponse>({
        query: GET_DELIVERY_ACCEPTANCE_CONSENT,
        variables: {
          locale: isVolvoFinans ? 'sv_SE' : userLanguage
        },
        fetchPolicy: 'network-only',
        context: { dato: true }
      })
      .then(res => {
        let translation = res.data.page.find(c => c.key === 'Default')?.value;

        if (isNonEmptyString(fspOrgNumber)) {
          const foundTranslation = res.data.page.find(
            c => c.key.toLowerCase() === fspOrgNumber.toLowerCase()
          );
          if (foundTranslation && foundTranslation.value) {
            translation = foundTranslation.value.replace('{comment}', comment);
          }
        }
        return translation ? translation : text('DeliveryAcceptanceConsent');
      });
  };

  const getFspVat = async (orgNumber: string) => {
    const { data: vatData, error } = await client.query<GetFspVatResponse>({
      query: GET_FSP_VAT,
      fetchPolicy: 'no-cache',
      context: { dato: true },
      variables: { organizationId: orgNumber }
    });

    if (error) {
      console.error('Error fetching fsp vat');
    } else if (vatData.res) {
      return vatData.res.vatNumber;
    }
  };

  const getAdditionals = async (isVolvoFinans: boolean, userLanguage: string, market: string) => {
    try {
      const { data: additionalsData } = await client.query<GetExtraDocAndEquipmentResponse>({
        query: GET_EXTRA_DOCS_AND_EQUIPMENT,
        fetchPolicy: 'no-cache',
        context: { dato: true },
        variables: {
          market: market,
          language: isVolvoFinans ? 'sv_SE' : userLanguage
        }
      });

      const items = additionalsData.handoverAdditionalEquipmentsAndDocument.items.map(o => {
        if (isNullishOrEmpty(o.text)) {
          return {
            name: o.name,
            text: o.default,
            category: o.category.name as 'Document' | 'Equipment'
          };
        }
        return {
          name: o.name,
          text: o.text,
          category: o.category.name as 'Document' | 'Equipment'
        };
      });

      return items;
    } catch (error) {
      console.dir(error);
      console.error('Error fetching additionals');
    }
  };

  const providerValue = {
    text,
    addTranslation,
    translation,
    getConsent,
    getFspVat,
    getAdditionals
  };

  return <DatoContext.Provider {...props} value={providerValue} />;
};

export { DatoContext };
export default DatoContextProvider;
