import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { DealerLocation } from './getLocation';

export const GET_LOCATIONS = gql`
  query locations($input: GetLocationsRequest!) {
    locations: getLocations(input: $input) {
      locations {
        id
        name
        marketId
        address
        timezone
        city
        archived
      }
      paginationToken
    }
  }
`;

export interface GetLocationsResponse {
  locations: {
    locations: Array<DealerLocation>;
    paginationToken: string | null;
  };
}

export interface GetLocationParams {
  input: {
    isBackwardsQuery?: boolean | null;
    paginationToken?: string | null;
    limit?: number | null;
  };
}

export const getLocationsMock = (
  variables: GetLocationParams,
  result: GetLocationsResponse
): {
  request: { query: DocumentNode; variables: GetLocationParams };
  result: { data: GetLocationsResponse };
} => ({
  request: {
    query: GET_LOCATIONS,
    variables: variables
  },
  result: {
    data: result
  }
});
