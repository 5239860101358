import React from 'react';

const DatoContext = React.createContext<
  | undefined
  | {
      text: (prop: string, testText?: string) => string;
      addTranslation: (list: { [value: string]: string }) => void;
      translation: { [value: string]: string };
      getConsent: (
        isVolvoFinans: boolean,
        userLanguage: string,
        fspOrgNumber: string | undefined,
        comment: string
      ) => Promise<string>;
      getFspVat: (orgNumber: string) => Promise<string | undefined>;
      getAdditionals: (
        isVolvoFinans: boolean,
        userLanguage: string,
        market: string
      ) => Array<{
        name: string;
        text: string;
        category: 'Document' | 'Equipment';
      }>;
    }
>(undefined);

export default DatoContext;
